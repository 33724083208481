import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import "./login.css";
import { useLocation } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyCkHipZRA2cIl7cSvGVR2NkorjMNdLMrlw",
  authDomain: "wip-host.firebaseapp.com",
  projectId: "wip-host",
  storageBucket: "wip-host.appspot.com",
  messagingSenderId: "859044371419",
  appId: "1:859044371419:web:51ea467660d43550b265fe",
  measurementId: "G-504T7EXHZT",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const Login = () => {
  const [platform, setPlatform] = useState("web");
  const [loggedIn, setLoggedIn] = useState(false);
  const [deepLink, setDeepLink] = useState(
    "workverseunity://workverse.com/game?level=10"
  );
  const [tokens, setTokens] = useState({});

//   document.getElementById("testId")
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search); // Get query string
    const platform = queryParams.get("platform"); // Replace 'myParam' with your query parameter name
    setPlatform(platform);

    const urlParams = new URLSearchParams(window.location.search);
    const method = urlParams.get("method");

    // window.location.replace = "workverseunity://workverse.com/game?level=10";

  
  }, []);

  
//   window.location.replace = "workverseunity://workverse.com/game?level=10";

  async function loginWithGoogle() {

    try {
      const result = await signInWithPopup(auth, provider);
      
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log(credential);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
  
      setTokens({
        accessToken,
        idToken,
      });
  
      // Handle deep link after successful login
      // Make sure the deep link action is tied to a user interaction
      const deepLink = `workverseunity://workverse.com/game?level=10;scheme=workverseunity;package=com.Workverse.Simulator;end`;
    // const deepLink = `https://main.d1ce0dynhw41dy.amplifyapp.com`;
      // Create a hidden link element to trigger the deep link
      const a = document.createElement("a");
      a.href = deepLink; // Set the deep link URL
      a.style.display = "none"; // Hide the <a> element
      a.rel = "noreferrer";
      // a.target = "_blank";
      document.body.appendChild(a); // Append it to the DOM
      window.location.href = deepLink;
  
      // Ensure that deep link is triggered by a user action, like a click
      a.click(); // This will trigger the deep link
  
      // Clean up by removing the <a> element after clicking
      document.body.removeChild(a);
  
      setLoggedIn(true); // User is logged in
    } catch (error) {
      // Handle Errors here
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData?.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      // Handle the error, maybe show a user-friendly message
    }
  }
  

  async function handleSignOut() {
    await auth.signOut().then(() => {console.log("Success")})
  }
  return (
    <div className="login-container">
      <div
        className="login-box"
        style={{ flexDirection: "column", display: "flex" }}
      >

          <div>
            <h2>Login</h2>

            <form className="login-form">
              <input
                type="email"
                placeholder="Enter your email"
                required
                disabled
              />
              <input
                type="password"
                placeholder="Enter your password"
                required
                disabled
              />
              <button type="submit" className="login-btn" disabled>
                Login with Email
              </button>
            </form>
            <hr />
            <div className="or-divider">
              <div className="extra-logins">
                <button onClick={loginWithGoogle} id="testId" >Login with Google</button>
                {platform === "ios" ? (
                  <button>Login with Apple Id</button>
                ) : null}
              </div>
            </div>
          </div>

          <>
            If you workverse simulator doesn't open automatically please{" "}
            <a
              href={`${deepLink}&accessToken=${tokens.accessToken}&idToken=${tokens.idToken}`}
            >
              {" "}
              Click Here{" "}
            </a>

            <button onClick={() => handleSignOut()} style={{marginTop: "20px"}}>Sign Out</button>
          </>
      </div>
    </div>
  );
};

export default Login;


 // window.location.href = deepLink;
        // // window.location.href = deepLink;
        // await fetch("https://subtle-largely-owl.ngrok-free.app/deep-link", {
        //     method: "POST"
        // })
        // await fetch("https://subtle-largely-owl.ngrok-free.app/deep-link", {
        //     method: "get"
        // }).then((res) => {
        //     window.location.href = "workverseunity://workverse.com/game?level=10"
        // })
        // const deepLink = "workverseunity://workverse.com/game?level=10"

        // <a href="intent://invoice/#Intent;scheme=company;package=YOUR_PACKAGE_NAME;end" rel="noreferrer" target="_blank">launch</a>

     