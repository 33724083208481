import "./App.css";
import Login from "./Login";

function App() {
  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", width: "400px" }}
    >

        <Login />
    </div>
  );
}

export default App;
